<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>积分商品</el-breadcrumb-item>
            <el-breadcrumb-item>添加/编辑</el-breadcrumb-item>

          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="序号" :error="$v.form.sorder.$error ? '请输入序号' : ''">
          <el-input v-model.number="form.sorder" type="number"></el-input>
        </el-form-item>


        <el-form-item label="所属商家" :error="$v.form.shopid.$error ? '请选择所属商家' : ''">

          <el-select v-model="form.shopid" filterable allow-create default-first-option placeholder="请选择所属商家">
            <el-option v-for="item in shopdata" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="商品标题" :error="$v.form.name.$error ? '请输入商品标题' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="首页图" :error="$v.form.homeImg.$error ? '请上传首页图' : ''">
          <image-upload @upload="imageUpload" :image="form.homeImg" />
        </el-form-item>
        <el-form-item label="轮播图组" :error="$v.form.homeImgarray.$error ? '请上传详情轮播图组' : ''">
          <images-upload @upload="bannerUpload" :imgList="form.homeImgarray" />
        </el-form-item>
        <el-form-item label="兑换积分" :error="$v.form.integral.$error ? '请输入' : ''">
          <el-input-number v-model="form.integral" :precision="0" :step="1"></el-input-number>
        </el-form-item>

        <el-form-item label="库存" :error="$v.form.stock.$error ? '请输入库存' : ''">
          <el-input v-model.number="form.stock"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label=0>下架</el-radio>
            <el-radio :label=1>上架</el-radio>

          </el-radio-group>
        </el-form-item>

        <el-form-item style="width:100%" label="描述" :error="$v.form.contensts.$error ? '请输入描述' : ''">
          <tinymce v-model="form.contensts" />

        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from '@/components/tinymce'
import ImageUpload from '@/components/imageUpload'
import ImagesUpload from '@/components/imagesUpload'
import FileUpload from '@/components/fileUpload'
import { editRequest, getdatilRequest } from '@/api/zrproduct'
import { getSelectRequest } from '@/api/zrproducttype'
import { getSelectshopRequest } from '@/api/zrshop'
import { required } from 'vuelidate/lib/validators'
import utils from '@/utils/util'
export default {
  name: 'Articletypeedit',
  data() {
    return {
      classify: [],
      shopdata: [],
      form: {
        id: 0,
        producttypeid: '',
        homeImgarray: [],
      },
      producttypeidtext: [],
      educationdata: [],
      constellationdata: [],
      zodiacdata: [],
      typeId: 0,
    }
  },
  components: {
    'image-upload': ImageUpload,
    'images-upload': ImagesUpload,
    'file-upload': FileUpload,
    tinymce: Tinymce,
  },
  created() {
    this.form.id = this.$route.query['id']
    this.typeId = this.$route.query['typeId']

    this.getSelect()
    this.getSelectshop()
    this.getDetail()
  },
  methods: {
    back() {
      this.$router.back()
    },
    bannerUpload(url) {
      console.log(url)
      this.form.homeImgarray = url
    },
    imageUpload(url) {
      this.form.homeImg = url
    },
    imageUploadistoplargeimg(url) {
      this.form.istoplargeimg = url
    },

    getSelect() {
      getSelectRequest().then((res) => {
        console.log(res)
        this.classify = res.data
      })
    },
    getSelectshop() {
      getSelectshopRequest().then((res) => {
        this.shopdata = res.data
      })
    },
    getDetail() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        res.data.homeImgarray = utils.ImgListFormat(res.data.homeImgarray)
        if (this.form.id == 0) {

          res.data.shopid = ''

        }

        this.form = res.data
      })
    },

    save() {
      this.$v.form.$touch()

      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))

        params.homeImgarray = utils.uploadImgFormat(params.homeImgarray)
        params.homeImg = utils.removeUrl(params.homeImg)
        params.price = 0
        params.producttypeid = 0
        params.istoplargeimg = utils.removeUrl(params.istoplargeimg)
        params.isintegral = 1
        editRequest(params).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },
  validations: {
    form: {
      name: {
        required,
      },

      sorder: {
        required,
      },
      contensts: {
        required,
      },
      integral: {
        required,
      },
      shopid: {
        required,
      },
      stock: {
        required,
      },
      homeImg: {
        required,
      },
      states: {
        required,
      },

      homeImgarray: { required },
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
